<template>
	<div>
		<Header title="次卡消费详情" @back="onBack"></Header>
		<a-spin :spinning="loading">
		<div>
			<a-row style="margin: 30px 0;">
				<a-col :span="3">
					<img style="width: 100%;margin: 0px 0 0 10px;max-width: 180px;" :src="detail.filmImg"/>
				</a-col>
				<a-col :offset="1" :span="6">
					<!-- <p>会员昵称：<span>甘肃天水时代影城</span></p> -->
					<p>次卡卡号：<span>{{ detail.cardNumber || '' }}</span></p>
					<p>余额消费后：<span>{{ detail.balanceAfter || '' }}</span></p>
					<p>影院名称：<span>{{ detail.cinemaName }}</span></p>
					<p>订单备注：<span>{{ detail.remark || '暂无' }}</span></p>
					<p>是否退款：<span>{{ detail.status === 'AFTERSALE_FINISH' ? '是' : '否' }}</span></p>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>昵称：<span>{{ detail.nickname || '' }}</span></p>
					<p>卡类型：<span>次卡</span></p>
					<p>余额实际消费：<span>{{ detail.balanceConsume || '' }}</span></p>
					<p>商品名称：<span>{{ detail.filmName || '' }}</span></p>
				</a-col>
				<a-col :offset="1" :span="6">
					<p>手机号：<span>{{ detail.userPhone || '' }}</span></p>
					<p>余额消费前：<span>{{ detail.balanceBefore || '' }}</span></p>
					<p>消费总额：<span>{{ detail.totalFee || '' }}</span></p>
					<p>商品订单描述：<span>{{ detail.cinemaName }}</span></p>
					<p>流水号：<span>{{ detail.orderNo }}</span></p>
				</a-col>
			</a-row>
			 
			 <div style="margin: 30px 0;text-align: center;">
				 <a-button @click="onBack">返回</a-button>
			 </div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { getCardSaleOrdreDetail } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data(){
			return {
				loading: false,
				detail: {}
			}
		},
		created(){
			this.getData();
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardSaleOrdreDetail({
						orderId: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.detail = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style scoped>
	p > span {
		color: rgba(0,0,0,.65);
	}
</style>
