<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;" @change="getCardPolicyList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row v-if="showAll">
					<a-form-item label="会员昵称" name="nickname" class="ui-form__item">
						<a-input v-model:value="formState.nickname" placeholder="请输入会员昵称"></a-input>
					</a-form-item>
					
					<a-form-item label="会员手机" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入会员手机"></a-input>
					</a-form-item>
					
					<a-form-item label="次卡卡号" name="cardNumber" class="ui-form__item">
						<a-input v-model:value="formState.cardNumber" placeholder="请输入次卡卡号"></a-input>
					</a-form-item>
					
					<a-form-item label="次卡等级" name="cardId" class="ui-form__item">
						<a-select v-model:value="formState.cardId" style="width: 180px;" placeholder="请选择次卡等级">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cardAllList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>
					
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<span v-permission="['coupon_timesCard_sale_export']">
							<exportComponent type="baseTimesCardVolume" :searchData="searchData"></exportComponent>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
						<!-- <a-button type="primary" @click="onDetail">添加组织</a-button> -->
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="orderId" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 2400 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.nickname || '-' }}</div>
							<div>会员手机：{{ record.userPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'balance'">
							<div>消费前：{{ record.balanceBefore }}</div>
							<div>消费后：{{ record.balanceAfter }}</div>
							<div>实际消费: {{ record.balanceConsume }}</div>
						</template>
						<template v-if="column.key === 'status'">
							<div>{{ transStatus(record.status) }}</div>
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-else-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_timesCard_sale_detail']" @click="onDetail(record)">
											<a-menu-item>
												详情
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<div v-if="showModal">
		<detail :id="id" @back="onBack"></detail>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import detail from './detail.vue';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getCardSaleList, getCardPolicyList } from '@/service/modules/coupon.js';
	export default {
		components: {Icon, detail, exportComponent },
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				cardAllList: [],
				formState: {
					couponType: 3,
					// organizationId: 0,
					cinemaId: 0,
					cardId: 0,
					source: 0,
          saleType:3,
				},
				searchData: {},
				id: 0,
				showModal: false,
				modelRef: {},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '订单号',
					dataIndex: 'orderNo'
				},  {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '卡等级名称',
					dataIndex: 'cardName'
				},{
					title: '会员信息',
					key: 'userInfo'
				},{
					title: '商品名称',
					dataIndex: 'filmName'
				}, {
					title: '余额消费',
					key: 'balance'
				},
				// {
				// 	title: '赠送消费'
				// },
				{
					title: '消费总额',
					dataIndex: 'balanceConsume'
				},{
					title: '交易状态',
					key: 'status',
					width: 100
				},
				// {
				// 	title: '是否退款'
				// },
				{
					title: '放映起始/结束时间',
					width: 200,
					key: 'showTime'
				},{
					title: '交易时间',
					key: 'createTime',
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '交易失败'
				}
				if (val === 'CHECKOUT_SUCCESS') {
					return '已下单'
				}
			},
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.cardId = this.searchData.cardId ? this.searchData.cardId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardSaleList({
						couponType: 3,
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
				
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				this.cardAllList = [];
				this.formState.cardId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getCardPolicyList(cinemaId) {
				this.cardAllList = [];
				this.formState.cardId = 0;
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 1,
					organizationId: this.formState.organizationId ? this.formState.organizationId : undefined,
					cinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			},
			onDetail(item) {
				this.id = item.orderId;
				this.showModal = true;
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
