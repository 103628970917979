import { send, upload } from "../index";

export function getCardPolicyList(data, opts = {}) {
	//  卡等级列表
  return send({
    url: "/admin/cardVolume/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCardPolicy(data, opts = {}) {
	//  卡等级保存
  return send({
    url: "/admin/cardVolume/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardPolicyDetail(data, opts = {}) {
	//  卡等级详情
  return send({
    url: "/admin/cardVolume/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCardPolicy(data, opts = {}) {
	//  卡等级修改
  return send({
    url: "/admin/cardVolume/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteCardPolicy(data, opts = {}) {
	//  卡等级删除
  return send({
    url: "/admin/cardVolume/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncCardPolicy(data, opts = {}) {
	//  卡等级同步政策
  return send({
    url: "/admin/cardVolume/syncCardPolicy.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardInfoList(data, opts = {}) {
	//  卡信息列表
  return send({
    url: "/admin/userCard/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardInfoDetail(data, opts = {}) {
	//  卡信息详情
  return send({
    url: "/admin/userCard/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getNumCardSearchList(data, opts = {}) {
	//  次卡信息详情表
  return send({
    url: "/admin/userCard/numCardSearchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesCardInfo(data, opts = {}) {
	//  启用卡信息
  return send({
    url: "/admin/userCard/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesCardInfo(data, opts = {}) {
	//  禁用卡信息
  return send({
    url: "/admin/userCard/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function delayCardInfo(data, opts = {}) {
	//  延期卡信息
  return send({
    url: "/admin/userCard/delay.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDelayCardInfo(data, opts = {}) {
	//  批量延期卡信息
  return send({
    url: "/admin/userCard/batchDelay.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unbundlingExchangeInfo(data, opts = {}) {
	//  解綁兑换券
  return send({
    url: "/admin/userCoupon/unbundling.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncVoucherByIds(data, opts = {}) {
	//  兑换券id批量同步
  return send({
    url: "/admin/userCoupon/syncVoucherByIds.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncVoucherByBatchNo(data, opts = {}) {
	//  兑换券批次号批量同步
  return send({
    url: "/admin/userCoupon/syncVoucherByBatchNo.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function importExcel(data, file) {
	// 导入兑换券
  return upload({
    url: "/admin/voucherImportRecord/importExcel.do",
    method: "POST",
    data,
    file: file,
  });
}

export function getExchangeList(data, opts={}) {
	// 兑换码列表
	return send({
	  url: "/admin/coupon/searchList.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function getExchangeInfoList(data, opts={}) {
	// 兑换券信息列表
	return send({
	  url: "/admin/userCoupon/searchList.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function delayExchange (data, opts ={}) {
	// 兑换码延期
	return send({
	  url: "/admin/userCoupon/delay.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function batchDelayExchange (data, opts={}) {
	// 兑换码批量延期
	return send({
	  url: "/admin/userCoupon/batchDelay.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function batchDelayExchangeAsync (data, opts={}) {
  // 兑换券信息批量异步延期
  return send({
    url: "/admin/userCoupon/asyncBatchDelay.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function asyncBatchDelayCoupon (data, opts={}) {
  // 兑换券列表批量异步延期
  return send({
    url: "/admin/coupon/asyncBatchDelayCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteImportRecord(data, opts = {}) {
	//  删除导入记录
  return send({
    url: "/admin/voucherImportRecord/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getImportRecord(data, opts = {}) {
	//  导入记录列表
  return send({
    url: "/admin/voucherImportRecord/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function generateCoupon(data, opts = {}) {
	//  批次生成券
  return send({
    url: "/admin/coupon/generateCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDelCoupon(data, opts = {}) {
	//  批量删除券
  return send({
    url: "/admin/coupon/batchDelCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function couponBatchDetail(data, opts = {}) {
	//  批次券详情
  return send({
    url: "/admin/coupon/couponBatchDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCinemaGoods(data, opts = {}) {
  //  修改卖品券等级
  return send({
    url: "/admin/voucherImportRecord/updateCinemaGoods.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateAndSync(data, opts = {}) {
	//  批次券修改并同步
  return send({
    url: "/admin/coupon/updateAndSync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateIsDisabled(data, opts={}) {
	// 优惠券启用禁用
	return send({
	  url: "/admin/coupon/updateIsDisabled.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function delCoupon(data, opts={}) {
	// 删除优惠券
	return send({
	  url: "/admin/coupon/delCoupon.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function delAndSync(data, opts = {}) {
	//  批次券删除并同步
  return send({
    url: "/admin/coupon/delAndSync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCouponSale(data, opts = {}) {
	//  优惠券消费
  return send({
    url: "/admin/coupon/couponConsume.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateIssue(data, opts = {}) {
	//  兑换码发放
  return send({
    url: "/admin/coupon/updateIssue.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function asyncBatchDelay(data, opts = {}) {
	//  兑换码批量延期
  return send({
    url: "/admin/coupon/asyncBatchDelay.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function bindVoucher(data, opts = {}) {
	//  兑换码绑定
  return send({
    url: "/admin/coupon/bindVoucher.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchBindVoucher(data, opts = {}) {
	//  兑换码批量绑定
  return send({
    url: "/admin/coupon/batchBindVoucher.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDelaySnackCoupon(data, opts = {}) {
	// 卖品券批量延期
  return send({
    url: "/admin/coupon/asyncBatchDelaySnackCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardSaleList(data, opts = {}) {
	// 卡消费
  return send({
    url: "/admin/cardConsume/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardSaleOrdreDetail(data, opts = {}) {
	// 卡消费订单详情
  return send({
    url: "/admin/cardConsume/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cancelCard(data, opts = {}) {
	// 退卡
  return send({
    url: "/admin/userCard/cancelCard.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getSnackSale(data, opts = {}) {
	// 小吃券消费
  return send({
    url: "/admin/coupon/saleConsume.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function importSaleCoupon(data, file) {
	// 小吃券导入
  return upload({
    url: "/admin/coupon/importSaleCoupon.do",
    method: "POST",
    data,
    file: file,
  });
}

export function saleBatchDisable(data, opts = {}) {
	// 小吃券批量禁用
  return send({
    url: "/admin/coupon/batchDisable.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saleBatchOpen(data, opts = {}) {
	// 小吃券批量启用
  return send({
    url: "/admin/coupon/batchEnable.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchUnbundling(data, opts = {}) {
	// 批量解绑
  return send({
    url: "/admin/userCoupon/batchUnbundling.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function modifyBalance(data, opts = {}) {
	// 用户充值卡修余额
  return send({
    url: "/admin/userCard/modifyBalance.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function unbinding(data, opts = {}) {
	// 新兑换券列表解绑
  return send({
    url: "/admin/coupon/unbinding.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function asyncBatchEnable(data, opts = {}) {
	// 异步批量启用券
  return send({
    url: "/admin/coupon/asyncBatchEnable.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function asyncBatchDisable(data, opts = {}) {
	// 异步批量禁用券
  return send({
    url: "/admin/coupon/asyncBatchDisable.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function asyncBatchUnbundling(data, opts = {}) {
	// 异步批量解绑券
  return send({
    url: "/admin/userCoupon/asyncBatchUnbundling.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoredCardList(data, opts = {}) {
	// 储值卡列表
  return send({
    url: "/admin/card/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function storedImport(data, file) {
	// 储值卡导入
  return upload({
    url: "/admin/card/importAdd.do",
    method: "POST",
    data,
    file: file,
  });
}

export function storedPullOnShelves(data, opts = {}) {
	// 储值卡列表
  return send({
    url: "/admin/card/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function storedPullOffShelves(data, opts = {}) {
	// 储值卡列表
  return send({
    url: "/admin/card/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function storedModifyBalance(data, opts = {}) {
	// 储值卡列表
  return send({
    url: "/admin/card/modifyBalance.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function bindStored(data, opts = {}) {
	// 储值卡列表
  return send({
    url: "/admin/card/bindCard.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCardCinema(data, opts = {}) {
  // 修改开卡影院
  return send({
    url: "/admin/card/updateCardCinema.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAdminUserCoupon(data, opts = {}) {
	// 获取设置
  return send({
    url: "/admin/voucherImportRecord/getAdminUserCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function setAdminUserCoupon(data, opts = {}) {
	// 修改设置
  return send({
    url: "/admin/voucherImportRecord/setAdminUserCoupon.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRechargeActivity(data, opts = {}) {
	// 储值卡活动列表
  return send({
    url: "/admin/rechargeActivity/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveRechargeActivity(data, opts = {}) {
	// 储值卡活动新增
  return send({
    url: "/admin/rechargeActivity/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRechargeActivityDetail(data, opts = {}) {
	// 储值卡活动详情
  return send({
    url: "/admin/rechargeActivity/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateRechargeActivity(data, opts = {}) {
	// 储值卡活动编辑
  return send({
    url: "/admin/rechargeActivity/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function delRechargeActivity(data, opts = {}) {
	// 储值卡活动删除
  return send({
    url: "/admin/rechargeActivity/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesRechargeActivity(data, opts = {}) {
	// 储值卡活动启用
  return send({
    url: "/admin/rechargeActivity/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesRechargeActivity(data, opts = {}) {
	// 储值卡活动启用
  return send({
    url: "/admin/rechargeActivity/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRechargeRecord(data, opts = {}) {
	// 储值卡充值记录
  return send({
    url: "/admin/rechargeRecord/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardBuying(data, opts = {}) {
	// 会员卡消费记录
  return send({
    url: "/admin/card/getCardBuying.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getQueryAddMoneyList(data, opts = {}) {
	// 会员卡充值记录
  return send({
    url: "/admin/card/queryAddMoneyList.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function importRecordExcel(data, file) {
	// 批次券导入兑换券
  return upload({
    url: "/admin/coupon/importCouponById.do",
    method: "POST",
    data,
    file: file,
  });
}

export function getTikTokSaleList(data, opts = {}) {
	// 抖音券消费列表
  return send({
    url: "/admin/tikTokTicketConsume/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getTikTokSaleDetail(data, opts = {}) {
	// 抖音券消费详情
  return send({
    url: "/admin/tikTokTicketConsume/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncCardInfo(data, opts = {}) {
	// 影城会员卡数据同步
  return send({
    url: "/admin/userCard/syncCardInfo.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cardUnbinding(data, opts = {}) {
	// 会员卡解绑
  return send({
    url: "/admin/card/unbinding.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cardChangesReport(data, opts = {}) {
  // 会员卡余额变动报表
  return send({
    url: "/admin/orderCardRecord/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function updateGoodsDiscount(data, opts = {}) {
  // 改变影院折扣
  return send({
    url: "/admin/cardVolume/updateGoodsDiscount.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function policyDetail(data, opts = {}) {
  // 政策详情
  return send({
    url: "/admin/cardVolume/policyDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updatePolicy(data, opts = {}) {
  // 修改政策
  return send({
    url: "/admin/cardVolume/updatePolicy.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function couponPolicyList(data, opts = {}) {
  // 查看卖品配置
  return send({
    url: "/admin/cinemaMiddle/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function batchDelete(data, opts = {}) {
  // 批量删除卖品配置
  return send({
    url: "/admin/cinemaMiddle/batchDelete.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function couponDelete(data, opts = {}) {
  // 单一删除卖品配置
  return send({
    url: "/admin/cinemaMiddle/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function batchSave(data, opts = {}) {
  // 保存卖品配置
  return send({
    url: "/admin/cinemaMiddle/batchSave.do",
    method: "POST",
    data,
    ...opts,
  });
}


